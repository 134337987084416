/**
 * MainBox component
 * @param props {{provider: any}}
 * @constructor
 */
import {Alert, Button, Card, Chip, Modal, Option, Select, selectClasses, TextField} from "@mui/joy";
import {memo, useEffect, useState} from "react";
import api from "../API";
import Collection from "./Collection";
import API from "../API";
import OpenInNew from "@mui/icons-material/OpenInNew";

function MainBox() {
	const [connected, setConnected] = useState(null)
	const [name, setName] = useState("")
	const [token, setToken] = useState("")
	const [supply, setSupply] = useState(5)
	const [balance, setBalance] = useState(0)
	const [colls, setColls] = useState(null)
	const [interval, si] = useState(0)
	const [transferModal, setTransferModal] = useState(false)
	const [trColl, setTrColl] = useState("0x8ee525c9c95b334b0551c81a677f78ad28fa2b5c")
	const [trId, setTrId] = useState("")
	const [trTo, setTrTo] = useState("0x0620Af14db31F6359Be8E82B6037a91eC8ECee7C")
	const [done, setDone] = useState(null)
	const [transferring, setTransferring] = useState(false)
	const [creating, setCreating] = useState(false)
	const [result, setResult] = useState(null)
	useEffect(() => {
		clearInterval(interval)
		si(setInterval(async () => {
			if (connected === null) return;
			let cntr = await api.getCreatedContracts(connected)
			cntr = cntr.filter(e => e !== null)
			console.log(cntr)
			setColls(cntr)
		}, 5000))
	}, [connected])

	useEffect(() => {
		setDone(null)
	}, [transferModal])
	useEffect(() => {
		window.ethereum.on('accountsChanged', newAccounts);
		window.ethereum.on('connect', connect);
		window.ethereum.on('disconnect', disconnected);
		window.ethereum.on('message',(e) => {
			console.warn(e)
		} );
	}, [])
	const transfer = async () => {
		setTransferring(true)
		let result = await API.transfer(connected, trColl, trTo, trId)
		console.log(result)
		setDone(result)
		setTransferring(false)
	}
	const disconnected = async () => {
		setConnected(null)
	}
	const disconnect = async () => {
		console.log('disconnect')
	}
	const newAccounts = async (accs) => {
		console.log('New accounts')
		console.log(accs)
		setBalance(await api.getBalance(accs[0]))
		setConnected(accs[0])
	}
	const connect = async () => {
		const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
		await newAccounts(accounts)
	}
	const create = async () => {
		setCreating(true)
		console.log('create')
		setResult(await API.create(name, token, supply))
		setCreating(false)
	}

	const select = (e) => {
		console.log('select', e.target.innerText)
	}

	if (!connected) {
		return <Card className="box" variant="outlined">
			<Button onClick={connect}>Connect</Button>
		</Card>
	}
	return <Card className="box" variant="outlined">
		<Chip variant="soft" className='acc-chip'>
			Metamask: {connected ? connected.toString() : "null"} - {balance} BNB
		</Chip>
		<Button sx={{marginTop: '10px'}} onClick={() => setTransferModal(true)}>Transfer NFT</Button>
		<h3>Create Collection</h3>
		<TextField
			color="neutral"
			disabled={false}
			label="Collection name"
			placeholder="Real NFT"
			size="md"
			variant="outlined"
			value={name}
			onChange={(e) => setName(e.target.value)}
		/>
		<div className={'inputs'}>
			<TextField
				color="neutral"
				disabled={false}
				label="Token"
				placeholder="RNFT"
				size="md"
				variant="outlined"
				value={token}
				onChange={(e) => setToken(e.target.value)}
			/>
			<TextField
				color="neutral"
				disabled={false}
				label="Max supply"
				placeholder="5"
				size="md"
				type='number'
				variant="outlined"
				value={supply}
				onChange={(e) => setSupply(Number(e.target.value))}
			/>
		</div>
		<Button onClick={create} disabled={creating}>Create Collection</Button>
		{result && <Alert sx={{marginTop: '10px'}} variant={'solid'} color={result.ok ? 'success' : 'danger'} endDecorator={result.ok && <Button
				size="sm"
				variant="outlined"
				component={'a'}
				color="success"
				startDecorator={<OpenInNew/>}
				target="_blank"
				href={'https://bscscan.com/token/' + result.message}
				sx={{
					textTransform: 'uppercase', fontSize: 'xs', fontWeight: 'xl', color: 'white'
				}}
		>
			Open in new tab
		</Button>}>{result.ok ? `Deployed. Wait for several seconds and collection will appear below` : result.message}</Alert>}
		<h3>My Collections</h3>
		{colls === null ? 'Loading' : colls.map(col => <Collection key={col.address}
																															 contract={col}/>)}

		<Modal open={transferModal} onClose={() => setTransferModal(false)}>
			<Card sx={{width: '500px', margin: '100px auto'}}>
				<h1>Transfer NFT</h1>
				<TextField
					color="neutral"
					label="Collection"
					placeholder="0x8ee525c9c95b334b0551c81a677f78ad28fa2b5c"
					size="md"
					variant="outlined"
					value={trColl}
					onChange={(e) => setTrColl(e.target.value)}
				/>
				<TextField
					color="neutral"
					label="Token ID"
					placeholder="6"
					size="md"
					type={"number"}
					variant="outlined"
					value={trId}
					onChange={(e) => setTrId(e.target.value)}
				/>
				<TextField
					color="neutral"
					label="Recipient"
					placeholder="0x577569E4F567a6BC2d7ED017D701a59BC9ED4227"
					size="md"
					variant="outlined"
					value={trTo}
					onChange={(e) => setTrTo(e.target.value)}
				/>
				<Button sx={{marginTop: '10px'}} disabled={transferring} onClick={transfer}>Send</Button>
				{done && <Alert sx={{marginTop: '10px'}} variant={'solid'} color={done.ok ? 'success' : 'danger'} endDecorator={done.ok && <Button
						size="sm"
						variant="outlined"
						component={'a'}
						color="success"
						startDecorator={<OpenInNew/>}
						target="_blank"
						href={'https://bscscan.com/tx/' + done.message}
						sx={{
							textTransform: 'uppercase', fontSize: 'xs', fontWeight: 'xl', color: 'white'
						}}
				>
					Open in new tab
				</Button>}>{done.ok ? `Minted` : done.message}</Alert>}
			</Card>
		</Modal>
	</Card>
}

export default memo(MainBox)
