import {BscScan} from "@jpmonette/bscscan";
import Web3 from "web3";
import abi from './abi.json'
import bytecode from './bytecode.json'
import * as ethers from "ethers";
import {ContractFactory} from "ethers";

class API {
	constructor() {
		this.client = new BscScan({apikey: 'EMPIHWEMBWE4I6V76YISSMW6S14KFX9CTR'}); // todo: your api key for bscscan.com
		this.web3 = new Web3(window.ethereum);
	}

	/**
	 *
	 * @param address
	 * @returns {Promise<number>}
	 */
	async getBalance(address) {
		return +(Number(await this.client.accounts.getBalance({address})) / 1000000000000000000).toFixed(3)
	}

	async getOwnedNFTs(address) {
		// this.client.accounts
	}

	async checkOwnership(contract, address, token) {
		let contractRef = new this.web3.eth.Contract(abi, contract)
		let result = await contractRef.methods.ownerOf(token).call()
		return result.toString().toUpperCase() === address.toString().toUpperCase()
	}

	async transfer(fromId, contractId, toId, tokenId) {
		if(!await this.checkOwnership(contractId, fromId, tokenId)) {
			return {ok: false, message: 'You do not own this NFT'}
		}
		try {
			let contract = new this.web3.eth.Contract(abi, contractId)
			console.log(toId, tokenId, window.ethereum.selectedAddress)
			const tx = {
				from: window.ethereum.selectedAddress, to: contractId, // this is the value in wei to send
				data: contract.methods.transferFrom(window.ethereum.selectedAddress, toId, +tokenId).encodeABI()
			}
			console.log(tx)
			try {
				const txHash = await window.ethereum.request({
					method: 'eth_sendTransaction', params: [tx]
				});
				console.log({txHash});
				return {ok: true, message: txHash}
			} catch (e) {
				return {ok: false, message: e.message}
			}
		} catch (e) {
			return {ok: false, message: e.message}
		}
	}

	async getCreatedContracts(address) {
		let trans = await this.client.accounts.getTxList({address})
		console.log(trans)
		return Promise.all(trans.filter(tx => tx.methodId === "0x60806040").map(async tx => {
			try {

				let contract = new this.web3.eth.Contract(abi, tx.contractAddress)
				let symbol = await contract.methods.symbol().call()
				let name = await contract.methods.name().call()
				let current = Number(await contract.methods.getCurrentSupply().call())
				let max = Number(await contract.methods.getMaxSupply().call())
				return {address: tx.contractAddress, symbol, name, current, max, owner: address, api: contract}

			} catch (e) {
				console.log(e)
				return null
			}
		}))
	}

	async create(name, token, supply = 5) {
		if(!name || name === '') {
			name = 'NFT' + Date.now()
		}
		if(!token || token === '') {
			token = 'Real NFT by ' + Date.now()
		}
		try {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			const factory = new ContractFactory(abi, bytecode, signer)
			const contract = await factory.deploy(supply, name, token)

			console.log(contract.address)
			console.log(contract.deployTransaction)
			return {ok: true, message: contract.address}
		} catch (e) {
			console.log(e)
			return {ok: false, message: e.message}
		}



	}
}

const api = new API()
export default api;
