import {Alert, Box, Button, Card, CircularProgress, Modal, ModalClose, Sheet, TextField, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import * as ethers from "ethers";
import OpenInNew from '@mui/icons-material/OpenInNew';

function Collection({contract}) {
	const [open, setOpen] = useState(false)

	function mintMore() {
		setOpen(true)
	}

	async function mint(address, func) {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();
		const owner = await signer.getAddress();

		const tx = {
			from: owner, to: contract.address, value: "0", // this is the value in wei to send
			data: contract.api.methods.mint(address, (contract.current + 1).toString() + '00' + Date.now().toString(), "").encodeABI()
		}
		console.log(tx)
		try {
			const txHash = await window.ethereum.request({
				method: 'eth_sendTransaction', params: [tx]
			});
			console.log({txHash});
			func(true, txHash)
		} catch (e) {
			console.error(e)
			func(false, e.message)
		}

	}

	return (<Card variant='outlined' color='primary' sx={{marginTop: '5px'}}>
		<Typography level="h2" fontSize="md" sx={{mb: 0.5}}>
			{contract.symbol}
		</Typography>
		<Typography level="body2">{contract.name}</Typography>
		<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<div>
				<Typography level="body3">Minted:</Typography>
				<Typography fontSize="lg" fontWeight="lg">
					{contract.current} / {contract.max}
				</Typography>
			</div>
			<div>
				<Button variant="outlined"
								size="sm"
								component={'a'}
								target="_blank"
								href={'https://bscscan.com/token/' + contract.address}
								color="primary"
								sx={{mr: '5px', fontWeight: 600}}>Explore</Button>
				{contract.current < contract.max && <Button
					variant="solid"
					size="sm"
					color="primary"
					sx={{ml: '5px', fontWeight: 600}}
					onClick={mintMore}
				>
					Mint more
				</Button>}
			</div>
			<MintModal open={open} onMint={(add, func) => mint(add, func)} onClose={() => setOpen(false)}
								 owner={contract.owner}/>
		</Box>
	</Card>)
}


function MintModal(props) {
	const [address, setAddress] = useState(props.owner)
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState(null)

	useEffect(() => {
		setLoading(false)
		setMessage(null)
	}, [props.open])

	function mint() {
		setLoading(true)
		setMessage(null)
		props.onMint(address, (ok, message) => {
			setLoading(false)
			setMessage({ok, message})
		})
	}

	return (<Modal
		aria-labelledby="modal-title"
		aria-describedby="modal-desc"
		open={props.open}
		onClose={() => props.onClose()}
		sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
	>
		<Sheet
			variant="outlined"
			sx={{
				width: 500, borderRadius: 'md', p: 3, boxShadow: 'lg',
			}}
		>
			<ModalClose
				variant="outlined"
				sx={{
					top: 'calc(-1/4 * var(--IconButton-size))',
					right: 'calc(-1/4 * var(--IconButton-size))',
					boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
					borderRadius: '50%',
					bgcolor: 'background.body',
				}}
			/>
			<Typography
				component="h2"
				id="modal-title"
				level="h4"
				textColor="inherit"
				fontWeight="lg"
				mb={1}
			>
				Mint NFT
			</Typography>
			<TextField label="Address to mint NFT" value={address} onChange={(e) => setAddress(e.target.value)}
								 placeholder="Address" variant="outlined"/>
			<Button disabled={loading} className={'mint-button'}
							startDecorator={loading && <CircularProgress variant="solid" thickness={2}/>} onClick={mint}>Mint</Button>
			{message && <Alert variant={'solid'} color={message.ok ? 'success' : 'danger'} endDecorator={message.ok && <Button
				size="sm"
				variant="outlined"
				component={'a'}
				color="success"
				startDecorator={<OpenInNew/>}
				target="_blank"
				href={'https://bscscan.com/tx/' + message.message}
				sx={{
					textTransform: 'uppercase', fontSize: 'xs', fontWeight: 'xl', color: 'white'
				}}
			>
				Open in new tab
			</Button>}>{message.ok ? `Minted` : message.message}</Alert>}
		</Sheet>
	</Modal>)
}

export default Collection