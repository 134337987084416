import './App.css';
import {CssVarsProvider} from '@mui/joy/styles';
import detectEthereumProvider from '@metamask/detect-provider'
import {useEffect, useState} from "react";
import Loading from "./components/Loading";
import NotSupported from "./components/NotSupported";
import MainBox from "./components/MainBox";


function App() {
	const [init, setInit] = useState(0) // 0 - loading, 1 - metamask installed, 2 - metamask not installed
	useEffect(() => {
		detectEthereumProvider().then(providerDetected)
	}, [])

	/**
	 *
	 * @param provider
	 * @returns {Promise<void>}
	 */
	const providerDetected = async (provider) => {
		if (provider) {
			setInit(1)
		} else {
			setInit(2)
		}
	}
	return (<CssVarsProvider>
		<div className='main'>
			{init === 0 && <Loading/>}
			{init === 1 && <MainBox/>}
			{init === 2 && <NotSupported/>}
		</div>
	</CssVarsProvider>);
}

export default App;
